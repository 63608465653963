<template lang="pug">
	section.authors(:class="!islocaleRuSystemAndIp ? 'is-en' : ''")
		.d-flex.justify-content-center.flex-wrap.authors__statistics.animation
			.authors__statistics-item
				span.authors__statistics-number {{ animatedNumber1 }}
				span.authors__statistics-txt {{ $t(`landingPage.authors.statistics[0]`) }}
			.authors__statistics-item
				span.authors__statistics-number {{ animatedNumber2 }}
				span.authors__statistics-txt {{ $t(`landingPage.authors.statistics[1]`) }}
			.authors__statistics-item
				span.authors__statistics-number {{ animatedNumber3 }}
				span.authors__statistics-txt {{ $t(`landingPage.authors.statistics[2]`) }}
			.authors__statistics-item
				span.authors__statistics-number {{ animatedNumber4 }}
				span.authors__statistics-txt {{ $t(`landingPage.authors.statistics[3]`) }}

		.authors__cases(v-if="islocaleRuSystemAndIp" id="authorsCases")
			h2.authors__title
				span.d-block.color-grey {{ $t(`landingPage.authors.title[0]`) }}
				span.d-block {{ $t(`landingPage.authors.title[1]`) }}

			.swiper-container(:style="{ marginLeft: marginLeftContainer, marginRight: marginLeftContainer }")
				.swiper-wrapper
					.swiper-slide.authors__card(v-for="(item, i) in 6" :key="`author-${i}`")
						.authors__item.is-cases
							.authors__cases-inner
								img.authors__cases-video(:src="`/assets/img/landingPage/Plain/authors/cases/case-${i+1}.webp`" @click="openVideo(i+1)")
								.authors__cases-play(@click="openVideo(i+1)")
									svg(width='47' height='46' viewbox='0 0 47 46' fill='none' xmlns='http://www.w3.org/2000/svg')
										path(d='M8.9502 16.8635C8.9502 8.8373 14.7097 5.55056 21.7572 9.56365L27.4431 12.7959L33.129 16.0282C40.1765 20.0413 40.1765 26.6148 33.129 30.6278L27.4431 33.8601L21.7572 37.0924C14.7097 41.1055 8.9502 37.8187 8.9502 29.7925V23.328V16.8635Z' fill='white' stroke='white' stroke-width='1.3' stroke-linecap='round' stroke-linejoin='round')
										path(opacity='0.01' fill-rule='evenodd' clip-rule='evenodd' d='M45.752 1.53906V45.1201H1.58984V1.53906H45.752Z' stroke='#007AA3' stroke-width='1.3')
							.authors__cases-desc
								h3.authors__cases-resource(v-html="$t(`landingPage.authors.list[${i}].nameResource`)")
								a.authors__link(:href="'https://paywall.pw/' + $t(`landingPage.authors.list[${i}].href`)" target="_blank") {{ $t(`landingPage.authors.list[${i}].href`) }}

		.authors__projects(v-if="islocaleRuSystemAndIp" id="authorsMainProjects")
			h2.authors__title
				span.d-block
					| {{ $t(`landingPage.projects.title[0]`) }}
					| {{ animatedNumber5 }}
					| {{ $t(`landingPage.projects.title[1]`) }}
				span.d-block.color-grey {{ $t(`landingPage.projects.title[2]`) }}

			.swiper-container.swiper-transition.is-projects
				.swiper-wrapper(:style="{ marginLeft: marginLeftContainer }")
					.swiper-slide.authors__card.is-project(v-for="(item, i) in sortedBestProjects" :key="`project-${i}`")
						a.d-block.authors__item.is-projects(:href="'https://paywall.pw/' + item.url.split('/')[item.url.split('/').length - 1]" target="_blank")
							img.authors__projects-picture(:src="'https://api.paywall.pw' + item.image" :alt="item.title" width="600" height="337")
							.d-flex.flex-column.justify-content-between.align-items-start.authors__projects-inner
								.w-100
									h3.authors__projects-name {{ item.title }}
									p.authors__projects-txt {{ item.description }}
								a.authors__link.is-projects(:href="'https://paywall.pw/' + item.url.split('/')[item.url.split('/').length - 1]" target="_blank") {{ 'paywall.pw/' + item.url.split('/')[item.url.split('/').length - 1] }}

		.video-modal.is-vertical(v-if='isShowVideo' @click="closeVideo()" ref="modalAuthorsVideo")
			video(autoplay loop controls playsinline :src="isMobile ? `/assets/video/landingPage/Plain/cases/case-${openSomeVideo}/videoMobile.mp4` : `/assets/video/landingPage/Plain/cases/case-${openSomeVideo}/video.mp4`" type='video/mp4')

			.close-modal
				.close-modal__icon
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Authors',
    props: ['marginLeftContainer', 'swiperProjects'],
    data: () => ({
        animatedNumber1: 3774,
        animatedNumber2: 6460,
        animatedNumber3: 78165,
        animatedNumber4: 2029,
        animatedNumber5: 3774,
        isShowVideo: false,
        lastScrollPosition: 0,
        openSomeVideo: null,
        savedSlideIndex: 0,
    }),
    mounted() {
        this.$nextTick(() => {
            this.setEqualHeightElems('.authors__cases-desc');
        });

        window.addEventListener('scroll', this.startAnimation);
    },
    beforeMount() {
        window.removeEventListener('scroll', this.startAnimation);
    },
    computed: {
        ...mapGetters({
            merchantsWithPayments: 'landing/merchantsWithPayments',
            authorsStatistics: 'landing/authorsStatistics',
        }),
        totalContentProjects() {
            return this.authorsStatistics ? this.authorsStatistics.projects.PLAIN.ACTIVE.total : '';
        },
        totalSubscriptions() {
            return this.authorsStatistics ? this.authorsStatistics.subscriptions.PLAIN.ACTIVE.total : '';
        },
        oldContentProjects() {
            return this.authorsStatistics ? this.authorsStatistics.projects.PLAIN.ACTIVE.old : '';
        },
        bestProjects() {
            return this.authorsStatistics ? this.authorsStatistics.projects.PLAIN.ACTIVE.favorites : '';
        },
        sortedBestProjects: function () {
            if (Array.isArray(this.bestProjects)) {
                const order = [
                    1207661314, 1578685496, 1139730766, 1830247751, 1961200207, 2068290124, 1736104692, 1944886866,
                    1400825745, 1849227632, 1533411183, 1502369483, 1157919619, 1736104692, 1944393934, 1290528515,
                    1192935701, 1905443137, 1886247062, 1770566421, 1454296540, 1903268835, 1982515147, 1443024226,
                    1528752133,
                ];
                return this.bestProjects.sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id));
            } else {
                return this.bestProjects;
            }
        },
    },
    methods: {
        startAnimation() {
            const currentScrollPosition = window.scrollY;

            if (document.querySelector('.authors__statistics')) {
                const authors = document.querySelector('.authors__statistics');
                const rectAuthors = authors.getBoundingClientRect();
                const isVisibleAuthors = rectAuthors.top < window.innerHeight - 30;

                if (
                    isVisibleAuthors &&
                    currentScrollPosition >= this.lastScrollPosition &&
                    this.animatedNumber1 != this.merchantsWithPayments &&
                    this.animatedNumber2 != this.totalContentProjects &&
                    this.animatedNumber3 != this.totalSubscriptions &&
                    this.animatedNumber4 != this.oldContentProjects &&
                    this.merchantsWithPayments != null &&
                    this.authorsStatistics != null
                ) {
                    const timer = setInterval(() => {
                        if (this.animatedNumber1 < this.merchantsWithPayments) {
                            this.animatedNumber1 += 1;
                        }
                        if (this.animatedNumber2 < this.totalContentProjects) {
                            this.animatedNumber2 += 1;
                        }

                        if (this.animatedNumber3 < this.totalSubscriptions) {
                            this.animatedNumber3 += 1;
                        }

                        if (this.animatedNumber4 < this.oldContentProjects) {
                            this.animatedNumber4 += 1;
                        }
                        if (
                            this.animatedNumber1 == this.merchantsWithPayments &&
                            this.animatedNumber2 == this.totalContentProjects &&
                            this.animatedNumber3 == this.totalSubscriptions &&
                            this.animatedNumber4 == this.oldContentProjects
                        )
                            clearInterval(timer);
                    }, 30);
                }

                const projects = document.querySelector('.authors__projects');

                if (projects) {
                    const rectProjects = projects.getBoundingClientRect();
                    const isVisibleProjects = rectProjects.top < window.innerHeight - 30;

                    if (
                        isVisibleProjects &&
                        currentScrollPosition >= this.lastScrollPosition &&
                        this.animatedNumber5 != this.merchantsWithPayments
                    ) {
                        const timerProjects = setInterval(() => {
                            if (this.animatedNumber5 < this.merchantsWithPayments) {
                                this.animatedNumber5 += 1;
                            }
                            if (this.animatedNumber5 == this.merchantsWithPayments) clearInterval(timerProjects);
                        }, 10);
                    }
                }

                this.lastScrollPosition = currentScrollPosition;
            }
        },
        openVideo(cardNumber) {
            this.isShowVideo = true;
            document.body.classList.add('modal-open');

            this.openSomeVideo = cardNumber;

            this.savedSlideIndex = this.swiperProjects.realIndex;
        },
        closeVideo() {
            if (event.target.tagName != 'VIDEO') {
                this.swiperProjects.update();

                setTimeout(() => {
                    this.swiperProjects.slideTo(this.savedSlideIndex, 0, false);
                }, 100);

                this.isShowVideo = false;
                document.body.classList.remove('modal-open');
            }
        },

        setEqualHeightElems(elements) {
            let elems = document.querySelectorAll(elements);

            let maxHeight = 0;

            for (let i = 0; i < elems.length; i++) {
                elems[i].style.height = 'auto';
            }

            for (let i = 0; i < elems.length; i++) {
                maxHeight = Math.max(maxHeight, elems[i].offsetHeight);
            }

            for (let i = 0; i < elems.length; i++) {
                elems[i].style.height = `${maxHeight}px`;
            }
        },
    },
    watch: {
        sortedBestProjects: {
            immediate: true,
            handler() {
                this.$nextTick(() => {
                    this.setEqualHeightElems('.authors__projects-inner');
                });
            },
        },
        merchantsWithPayments: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.animatedNumber1 = newVal - 1500;
                    this.animatedNumber5 = newVal - 1500;
                }
            },
        },
        totalContentProjects: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.animatedNumber2 = newVal - 1500;
                }
            },
        },
        totalSubscriptions: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.animatedNumber3 = newVal - 1500;
                }
            },
        },
        oldContentProjects: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.animatedNumber4 = newVal - 1500;
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.authors {
    padding-top: 283px;

    @media (max-width: 767.98px) {
        padding-top: 187.5px;
    }

    @media (max-width: 575.98px) {
        padding-top: 92px;
    }

    &.is-en {
        padding-top: 90px;

        @media (max-width: 767.98px) {
            padding-top: 80px;
        }

        @media (max-width: 575.98px) {
            padding-top: 70px;
        }
    }

    &__statistics {
        margin-bottom: 104px;

        @media (max-width: 1150px) {
            max-width: 800px;
            margin: 0 auto 104px;
        }

        @media (max-width: 767.98px) {
            margin-bottom: 79px;
        }

        @media (max-width: 575.98px) {
            margin-bottom: 54px;
        }

        &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 200px;
            min-height: 122.5px;
            width: 267px;
            background-color: var(--lc-light);
            color: var(--lc-dodger-blue);
            margin: 0 10px 16px;

            @media (max-width: 1150px) {
                margin-left: 8px;
                margin-right: 8px;
                width: calc(50% - 16px);
            }

            @media (max-width: 767.98px) {
                min-height: 113px;
            }

            @media (max-width: 575.98px) {
                width: calc(100% - 16px);
                min-height: 104px;
            }
        }

        &-number {
            font-weight: 700;
            font-size: 40px;
            line-height: 64px;
            letter-spacing: -0.64px;
        }

        &-txt {
            letter-spacing: -0.64px;
            line-height: 26px;
            margin-top: 2.5px;

            @media (max-width: 767.98px) {
                margin-top: -4px;
            }

            @media (max-width: 575.98px) {
                margin-top: -10px;
            }
        }
    }

    &__title {
        text-align: center;
        margin-bottom: 38px !important;
        padding: 0 16px;

        @media (max-width: 767.98px) {
            margin-bottom: 25px !important;
            padding: 0 12.5px;
        }

        @media (max-width: 575.98px) {
            margin-bottom: 12px !important;
        }
    }

    &__card {
        margin-right: 32px;
        padding-top: 20px;
        padding-bottom: 20px;
        min-height: 100%;
        width: 270px;
        animation: fillHeight 0s 0.2s linear forwards;

        @media (max-width: 767.98px) {
            margin-right: 24px;
            width: 236.5px;
        }

        @media (max-width: 575.98px) {
            margin-right: 16px;
            width: 203px;
        }

        &.is-project {
            display: flex;
            flex-direction: column;
            width: 600px;

            @media (max-width: 767.98px) {
                width: 444px;
            }

            @media (max-width: 575.98px) {
                width: 287.5px;
            }
        }
    }

    &__item {
        width: 100%;
        border-radius: 20px;
        transition: transform var(--la-time);

        &:has(.authors__cases-play:hover) {
            transform: scale(1.02);
        }

        &.is-projects {
            box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
            color: var(--lc-ebony) !important;
            transition: transform var(--la-time);
            height: 100%;

            &:hover {
                transform: scale(1.02);

                @media (max-width: 767.98px) {
                    transform: none;
                }
            }
        }

        &.is-cases {
            padding-bottom: 15px;
            border: 1px solid var(--lc-light-grayish-blue);
        }
    }

    &__link {
        margin-left: 15px;
        font-size: 14px;
        line-height: 25px;
        position: relative;

        &.is-projects {
            margin-left: 0;

            &:after {
                top: 2px;
            }
        }

        &:after {
            content: '';
            position: absolute;
            right: -26px;
            top: -4px;
            width: 22px;
            height: 21px;
            background: url("data:image/svg+xml,%3Csvg width='22' height='21' viewBox='0 0 22 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.2984 10.5379L13.0936 5.25684L7.8125 7.46171' stroke='%2352A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.78619 15.7412L13.0508 5.36328' stroke='%2352A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
                no-repeat center;
            transition: background-image var(--la-time);
        }

        &:hover {
            &:after {
                background-image: url("data:image/svg+xml,%3Csvg width='22' height='21' viewBox='0 0 22 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.2984 10.5379L13.0936 5.25684L7.8125 7.46171' stroke='%230071F4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.78619 15.7412L13.0508 5.36328' stroke='%230071F4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            }
        }
    }

    &__cases {
        overflow: hidden;

        .swiper-wrapper {
            @media (min-width: 2148px) {
                justify-content: center;
            }
        }

        .swiper-slide {
            &:last-child {
                margin-right: 0;
            }
        }

        &-inner {
            position: relative;
            height: 478.6px;

            @media (max-width: 767.98px) {
                height: 419px;
            }

            @media (max-width: 575.98px) {
                height: 360px;
            }
        }

        &-video {
            width: calc(100% + 1px);
            margin-top: -0.5px;
            margin-left: -0.5px;
            height: 100%;
            border-radius: 20px;
            object-fit: cover;
        }

        &-resource {
            font-weight: 700 !important;
            margin-bottom: 6px !important;
            margin-top: 21px !important;
            margin-left: 15px !important;
            font-size: 16px !important;
            line-height: 16px !important;
        }

        &-play {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            cursor: pointer;
            background-color: var(--lc-transparent-2-white);
            border-radius: 50%;
            width: 76px;
            height: 75px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__projects {
        margin-bottom: 62px;
        margin-top: 61px;

        @media (max-width: 767.98px) {
            margin-bottom: 56px;
            margin-top: 56px;
        }

        @media (max-width: 575.98px) {
            margin-bottom: 50px;
            margin-top: 50px;
        }

        .swiper-wrapper {
            margin-bottom: 20px;
        }

        &-picture {
            width: 100%;
            height: 337.5px;
            border-radius: 20px 20px 0 0;
            object-fit: cover;

            @media (max-width: 767.98px) {
                border-radius: 14px 14px 0 0;
                height: 249.5px;
            }

            @media (max-width: 575.98px) {
                border-radius: 8px 8px 0 0;
                height: 161.5px;
            }
        }

        &-inner {
            padding: 21px 18.5px 19px;
            border: 1px solid var(--lc-light-grayish-blue);
            border-top: 0;
            border-radius: 0 0 20px 20px;
            height: calc(100% - 337.5px);

            @media (max-width: 767.98px) {
                border-radius: 0 0 14px 14px;
                padding-top: 16px;
                padding-bottom: 14px;
                height: calc(100% - 249.5px);
            }

            @media (max-width: 575.98px) {
                border-radius: 0 0 8px 8px;
                padding-top: 11px;
                padding-bottom: 9.5px;
                height: calc(100% - 161.5px);
            }
        }

        &-name {
            font-size: 24px !important;
            font-weight: 700 !important;
            line-height: 28px !important;

            @media (max-width: 767.98px) {
                font-size: 21px !important;
            }

            @media (max-width: 575.98px) {
                font-weight: 600 !important;
                font-size: 18px !important;
            }
        }

        &-txt {
            line-height: 22px;
            font-weight: 400;
            margin-top: 10.5px;
            margin-bottom: 7.5px;
            -webkit-line-clamp: 4;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;

            @media (max-width: 767.98px) {
                font-size: 15px;
            }

            @media (max-width: 575.98px) {
                font-size: 14px;
                margin-top: 9px;
                margin-bottom: 10px;
            }
        }
    }

    @keyframes fillHeight {
        0% {
            height: auto;
        }
        100% {
            height: 100%;
        }
    }

    .swiper-projects {
        width: 100%;
        height: auto;
    }

    .swiper-transition {
        .swiper-wrapper {
            transition-timing-function: linear;
        }
    }

    .swiper-container.is-projects {
        box-sizing: border-box;
        width: 100%;
        overflow: hidden;
    }
}
</style>
